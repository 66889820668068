import React from "react"
import { Container } from "theme-ui"
import { StyledSection2 ,StyledSection, StyledSection3} from "./styles"
import { AnimatedWave } from "./"

const Section = ({
  backgroundColor = "#fff",
  children,
  light,
  id,
  enableWave,
  waveBottom,
  waveGradient,
  waveGradient2
}) => {
  if(waveGradient){
  
    return (
      <StyledSection2
        backgroundColor={backgroundColor}
        light={light || false}
        id={id}
      >
        <Container>{children}</Container>
        <AnimatedWave enableWave={enableWave} bottom={waveBottom} />
      </StyledSection2>
    )
  } else if(waveGradient2){
    return (
      <StyledSection3
        backgroundColor={backgroundColor}
        light={light || false}
        id={id}
      >
        <Container>{children}</Container>
        <AnimatedWave enableWave={enableWave} bottom={waveBottom} />
      </StyledSection3>
    )


   }else {
    return (
      <StyledSection
        backgroundColor={backgroundColor}
        light={light || false}
        id={id}
      >
        <Container>{children}</Container>
        <AnimatedWave enableWave={enableWave} bottom={waveBottom} />
      </StyledSection>
    )
  }

}

export default Section
