import React from "react"
import { Container, Styled } from "theme-ui"
import { StyledHeader, Nav, NavItem, NavList, NavLink } from "./styles"
import { StaticImage } from "gatsby-plugin-image"
const Header = () => {
  const [originalOffset, setOriginalOffset] = React.useState(30);
  const [shadow, setShadow] = React.useState(false);
  const [color, setColor] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  React.useEffect(() => {

 var xhrw = new XMLHttpRequest();
 xhrw.open("POST", '/give_me_your_number/', true);  
   xhrw.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
   xhrw.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token"))
   xhrw.send('try');
   xhrw.onreadystatechange =  function() { 
   if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {

 var ServerResponse = JSON.parse(xhrw.responseText);
   if (ServerResponse.err){
    setLoggedIn(false)


 } else {
  setLoggedIn(true)

 }}};




  }, []);

  function handleScroll() {
 

  
    if(originalOffset <= window.scrollY){
  
      setShadow(true)
 
    } else {
   
      setShadow(false)

    }
    if(originalOffset*2 <= window.scrollY){
  
      setColor(true)
 
    } else {
   
      setColor(false)

    }
  
 
   }


  
  return (
    <StyledHeader>
      <Container>
        <Nav style={shadow ?{boxShadow:'3px 3px 5px rgba(0, 0, 0, 0.4)', opacity:0.9,backgroundColor:color? 'black':'#f67a40'}:{backgroundColor:'#f67a40'}}>
        <StaticImage src="../images/newlogo-skeletal.png" alt={'imageAlt'} style={{marginRight:10, margin:10, opacity:shadow ?1:0, transition:'all 0.5s'}} placeholder={'blurred'} fit={'contain'} height={40} />
         { !loggedIn ?         <a href="/login" style={{textDecoration: 'none',paddingRight:15, paddingLeft:15,marginRight:'1.5rem',backgroundColor:'rgb(0, 183, 255)', borderRadius:15,boxShadow:color ? '1px 1px 5px #00b7ff7a':'3px 3px 5px #00000070'}}><div style={{height:40, display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>Log In</div></a>
:          <a href="/profile" style={{textDecoration: 'none',paddingRight:15, paddingLeft:15,marginRight:'1.5rem',backgroundColor:'rgb(0, 183, 255)', borderRadius:15,boxShadow:color ? '1px 1px 5px #00b7ff7a':'3px 3px 5px #00000070'}}><div style={{height:40, display:'flex', justifyContent:'center', alignItems:'center', color:'white', fontWeight:'bold'}}>Profile</div></a>
}
        </Nav>
      </Container>
    </StyledHeader>
  )
}

export default Header
