import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StyledHero, SplashImageObj } from "./styles"
import { AnimatedWave } from "./"
import { StaticImage } from "gatsby-plugin-image"

const Hero = ({ imageAlt, id }) => {


  return (
    <StyledHero id={id}>
    <div style={{ display:'flex',width:'100%', alignItems:'center',justifyContent:'space-around'}}>
      <StaticImage  src="../images/iphone-short-stories-read.png" alt={imageAlt} style={SplashImageObj} placeholder={'blurred'} fit={'contain'} width={500} />
   <div className='home-large-display-only' style={{display:'flex', flexDirection:'column'}}>
   <StaticImage src="../images/newlogo-skeletal.png" alt={imageAlt} style={{marginRight:'1vw'}} placeholder={'blurred'} fit={'contain'} width={500} />
      <h2 style={{maxWidth:500, color:'#ffff'}}>Effortless language learning combining flashcards with instant single-touch translation.
</h2>

   </div>
       </div>
      <AnimatedWave enableWave bottom={true} />
    </StyledHero>
  )
}

export default Hero
